var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "solutions-area section-width ptb-100",
      attrs: { id: "document" }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-title" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_2_6")))]),
          _c("p", [
            _vm._v(
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
            )
          ])
        ]),
        _c("div", { staticClass: "row justify-content-center mt-5" }, [
          _c("div", { staticClass: "category-navigate" }, [
            _c(
              "ul",
              _vm._l(_vm.tagList, function(item, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.getFileList(item.tag_name)
                      }
                    }
                  },
                  [
                    _c(
                      "li",
                      {
                        class: [
                          "btn btn-primary btn-lg",
                          { active: item.tag_name == _vm.tagSelected }
                        ],
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.selectTag(item.tag_name, true)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(item.tag_name) + " ")]
                    )
                  ]
                )
              }),
              0
            )
          ])
        ]),
        _c("div", { staticClass: "container" }, [
          _c("table", { staticClass: "table table-striped" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("table.th_1")))
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("table.th_2")))
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("table.th_3")))
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("table.th_4.title")))
                ])
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.items, function(item, index) {
                return _c("tr", { key: index }, [
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(item.更新時間))
                  ]),
                  _c("td", [_vm._v(_vm._s(item.檔案名稱))]),
                  _c("td", [_vm._v(_vm._s(item.檔案大小))]),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary btn mr-1",
                        attrs: {
                          href: item.download,
                          target: "_blank",
                          size: "sm"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("table.th_4.btn")) + " ")]
                    )
                  ])
                ])
              }),
              0
            )
          ])
        ])
      ]),
      _c(
        "div",
        {
          class: [
            "row",
            "text-center",
            { "is-show-next-page": _vm.isShowNextPage }
          ]
        },
        [
          _c("div", { staticClass: "col-lg-12 read-more" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                on: {
                  click: function($event) {
                    return _vm.getNextDocumentList()
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("pageBtn"))),
                _c("i", { staticClass: "fas fa-chevron-right ml-2" })
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }